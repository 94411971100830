<template>
	<section class="content">
		<div class="flex justify-content-center">
			<div class="w-100 card">
				<div class="card-body">
					<div class="row">
						<div class="col-12 col-sm-6 col-md-4">
							<div class="info-box">
								<span class="info-box-icon bg-info elevation-1"
									><i class="fas fa-bookmark"></i
								></span>
								<div class="info-box-content">
									<h5 class="info-box-text">TOTAL TICKETS</h5>
									<h5 class="info-box-number">{{ allTicket.total }}</h5>
								</div>
								<!-- /.info-box-content -->
							</div>
							<!-- /.info-box -->
						</div>
						<div class="col-12 col-sm-6 col-md-4">
							<div class="info-box">
								<span class="info-box-icon bg-warning elevation-1"
									><i class="fas fa-inbox"></i
								></span>
								<div class="info-box-content">
									<h5 class="info-box-text">PENDING TICKETS</h5>
									<h5 class="info-box-number">{{ allTicket.pending }}</h5>
								</div>
								<!-- /.info-box-content -->
							</div>
							<!-- /.info-box -->
						</div>
						<div class="col-12 col-sm-6 col-md-4">
							<div class="info-box">
								<span class="info-box-icon bg-success elevation-1"
									><i class="fas fa-check-double"></i
								></span>
								<div class="info-box-content">
									<h5 class="info-box-text">CLOSED TICKETS</h5>
									<h5 class="info-box-number">{{ allTicket.closed }}</h5>
								</div>
								<!-- /.info-box-content -->
							</div>
							<!-- /.info-box -->
						</div>
					</div>
					<div class="row">
						<div class="col-9">
							<div class="row form-horizontal">
								<div class="col-auto">
									<label class="control-label my-2 ml-2">
										<h6>Filter Pencarian</h6>
									</label>
								</div>
								<div class="col-auto">
									<select class="form-control" @change="dataType($event)">
										<option value="createdOn">Dibuat Antara</option>
										<option value="lastTm">Update Antara</option>
									</select>
								</div>
								<div class="col-auto">
									<div class="input-group mb-3">
										<input type="text" ref="daterange" class="form-control" style="border-right: 0" />
										<div class="input-group-append">
											<div class="input-group-text" style="background-color: #fff">
												<span><span class="fa fa-calendar"></span></span>
											</div>
										</div>
									</div>
								</div>
								<div class="col-auto">
									<select class="form-control" @change="ticketType($event)">
										<option value="" disabled selected>Status</option>
										<option value="open">Open</option>
										<option value="closed">Closed</option>
										<option value="pending">Pending</option>
									</select>
								</div>
							</div>
						</div>
						<div class="col-3 text-right">
							<div class="btn-group" ref="toolbar">
								<button type="button" data-action="read" class="btn btn-default"><i
										class="fa fa-sync-alt"></i></button>
								<button type="button" :disabled="downloading" v-on:click="downloadXlsx"
									data-action="export-xlsx" class="btn btn-default"><i class="fa fa-download"></i> <span class="d-none d-md-inline-block">Download</span></button>
							</div>
						</div>
					</div>
					<div class="table-ticket">
						<table class="table table-hover" ref="tblticket" id="tblticket">
							<thead>
								<tr>
									<th>AWB</th>
									<th>JUDUL</th>
									<th>PIC</th>
									<th>PRIORITAS</th>
									<th>STATUS</th>
									<th>STATUS KIRIMAN</th>
									<th>DIBUAT</th>
									<th>UPDATE</th>
									<th>UMUR</th>
									<th>KOMENTAR</th>
								</tr>
							</thead>
							<tbody @click="handleClick"></tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<ArsipTicketComment :show="isShowTicket" :orderId="orderCodeComment" />
	</section>
	<!-- /.content -->
</template>
<script>
import { createTable, authFetch, auth } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import 'jquery-ui';
import Daterangepicker from "daterangepicker";
import "daterangepicker/daterangepicker.css";
import ArsipTicketComment from "@/dialog/ArsipTicketComment";
import moment from "moment";

export default {
	name: "Ticket",
	data() {
		return {
			dateStart: moment("2021-01-01").format("YYYY-MM-DD"),
			dateEnd: moment().format("YYYY-MM-DD"),
			method: "",
			roles: "",
			allTicket: {},
			downloading: false,
			show: false,
			filter: {},
			form: {
			status: "",
			},
			dt1: moment("2021-01-01"),
			dt2: moment(),
			data_type: "createdOn",
			ticket_type: "",
			isShowTicket: false,
			orderCodeComment: ''
		};
	},
	watch:{
		$route (to, from){
			if(to.path === "/settings/tickets/all")
			{
				sessionStorage.clear();
				this.table.api().ajax.reload();
				this.loadAllStatus();
			}
		}
	},
	created: function () {
		this.roles = this.$route.meta.roles;
		this.userInfo = auth.user();
		if(this.$route.params.filter) {
			this.filter = this.$route.params.filter;
			this.loadAllStatus(this.filter);
		} else {
				this.filter.dt1 = this.dt1.format('YYYY-MM-DD');
				this.filter.dt2 = this.dt2.format('YYYY-MM-DD');
				this.loadAllStatus(this.filter);
		}

		if(this.filter.dt1 && this.filter.dt2)
		{
			this.dt1 = moment(this.filter.dt1);
			this.dt2 = moment(this.filter.dt2);
		} else {
				this.filter.dt1 = this.dt1.format('YYYY-MM-DD');
				this.filter.dt2 = this.dt2.format('YYYY-MM-DD');
		}
		this.dateStart = this.dt1.format('YYYY-MM-DD');
		this.dateEnd = this.dt2.format('YYYY-MM-DD');
		if(this.filter.gid)
		{
			this.groupId = this.filter.gid;
			this.dateStart = '';
			this.dateEnd = '';
		}
		if(Object.keys(this.filter).length<1)
		{
			if(sessionStorage.filterData)this.filter = JSON.parse(sessionStorage.filterData);
		}
		else{
			sessionStorage.filterData = JSON.stringify(this.filter);
			sessionStorage.filterStatus = 1;
		}
		this.filter.datatype = this.data_type;

		if (this.filter.open > 0) {
				this.filter.ticket = 'open';
		} else if (this.filter.closed > 0) {
				this.filter.ticket = 'closed';
		} else {
				this.ticket_type = '';
		}
	},
	methods: {
		onDate() {
			this.table.api().ajax.reload();
		},
		dataType(event) {
			this.data_type = event.target.value;
			this.filter.datatype = this.data_type;
		},
		ticketType(event) {
			sessionStorage.clear();
			let self = this;
			self.filter = {};
			self.ticket_type = event.target.value;
			self.filter.ticket = self.ticket_type;
			self.filter.dt1 = self.dateStart;
			self.filter.dt2 = self.dateEnd;
			self.filter.datatype = self.data_type;
			self.onDate();
			self.loadAllStatus(self.filter.ticket);
		},
		loadAllStatus(params) {
			var data = '';
			if (!params) {
				data = '';
			} else if(params == 'open' || params == 'closed' || params == 'pending'){
				var ticket = { ticket: params, dt1: this.dateStart, dt2: this.dateEnd };
				data = new URLSearchParams(ticket);
			}else {
				data = Object.keys(params)
					.map(
						(key) =>
							encodeURIComponent(key) + "=" + ((params[key] ===false || params[key] === undefined)?0 : encodeURIComponent(params[key]))
					)
					.join("&");
			}

			let url = '';
			if (params) {
				url = "/ticket/history/arsip_all_tickets?" + data;
			} else {
				url = "/ticket/history/arsip_all_tickets";
			}

				authFetch(url)
				.then((res) => {
					if (res.status === 201) {
					} else if (res.status === 400) {
					}
					return res.json();
				})
				.then((js) => {
					this.allTicket = js.data;
				});
		},
		downloadXlsx: function (e) {
			this.downloading = true;
			var filter = JSON.parse(JSON.stringify(this.filter));
			var data = Object.keys(filter)
				.map(
					(key) =>
					encodeURIComponent(key) + "=" + (filter[key] === false ? 0 : encodeURIComponent(filter[key]))
				)
				.join("&");
			var table = $('#tblticket').DataTable();
			var length = table.page.info().recordsTotal;
			
			if (length > 20000) {
				Swal.fire("Proses gagal", `Maksimal data AWB sebanyak kurang lebih 20.000 data.`, "error");
				this.downloading = false;
				return false;
			} else {
				authFetch("/ticket/history/arsip_download", {
					method: "POST",
					body: data,
				})
				.then((response) => response.blob())
				.then((blob) => {
					setTimeout(() => {
						this.downloading = false;
					}, 1000);
					var url = window.URL.createObjectURL(blob);
					var a = document.createElement("a");
					a.href = url;
					a.download = "Arsip List Ticket.xlsx";
					document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
					a.click();
					a.remove(); //afterwards we remove the element again
				});
			}
		},
		infoClaim(claim){
			var statusClaim = '';
			if (claim == 1)
				statusClaim = '<span class="badge badge-danger">Paket Diclaim</span>';
			else if (claim == 2)
				statusClaim = '<span class="badge badge-success">Claim disetujui</span>';
			else if (claim == 3)
				statusClaim = '<span class="badge badge-danger">Claim ditolak</span>';
			return statusClaim;
		},
		infoStatus(track) 
		{
			var output = '';
			if (track.cancel == 1)
				output += '<span class="badge badge-danger">Cancel</span>';
			else if (track.pickupStatus == 0)
				output += '<span class="badge badge-inverse">DRAFT</span>';
			else
			{
				if (track.trackingStatus == 0)
				{
					if(parseInt(track.order_type) == 1){
						output += '<span class="badge badge-secondary">Menunggu Drop</span>';
					} else {
						output += '<span class="badge badge-secondary">Menunggu Pickup</span>';
					}
					output += this.infoClaim(track.retClaim);
				}
				else if (track.trackingStatus == 1)
				{
					output += '<span class="badge badge-warning">Proses</span>';
					output += this.infoClaim(track.retClaim);
				}
				else if (track.trackingStatus == 2)
				{
					output += '<span class="badge badge-success">Terkirim</span>';
					output += this.infoClaim(track.retClaim);
				}
				else if (track.trackingStatus == 3)
				{
					output += '<span class="badge badge-warning">Proses Retur</span>';
					output += this.infoClaim(track.retClaim);
				}
				else if (track.trackingStatus == 4) {
					if (track.retStatus == 1) 
					{
						output += '<span class="badge badge-success">Retur Diterima</span>';
						output += this.infoClaim(track.retClaim);
					}
					else if (!track.retStatus)
					{
						output += '<span class="badge badge-info">Retur Diserahkan</span>';
						output += this.infoClaim(track.retClaim);
					}
				}
				else if (track.trackingStatus == 5) 
				{
					if (track.problem_description != null){
						output += ' <span class="badge badge-danger">Paket Bermasalah - '+track.problem_description+'</span>';
					} else {
						output += ' <span class="badge badge-danger">Paket Bermasalah</span>';
					}
					output += this.infoClaim(track.retClaim);
				}
			}
			return output;
		},
		handleClick(e) {
			if (e.target.matches(".link-order")) {
					let route = this.$router.resolve({
							path: "/arsip/detail/" + e.target.dataset.id
					});
						window.open(route.href,"_blank");
			}
			if(e.target.closest('.btn-comment')) {
				var el = e.target.closest('.btn-comment');
				this.$root.$emit("openArsipTicketComment", el.dataset.id);
				return false;
			}
		},
	},
	mounted() {
		this.$gtag.event(`page_view`, {
			'event_name': 'Arsip List Tiket'
		});
		const e = this.$refs;
		const self = this;
		this.$root.$on('reloadDashboardTicket', () => {
				this.loadAllStatus();
				this.table.api().ajax.reload( null, false ); // user paging is not reset on reload
		})
		this.$root.$on('openArsipTicketComment', (e) => {
			this.orderCodeComment = e;
			this.isShowTicket = !this.isShowTicket;
		})
		new Daterangepicker(
			this.$refs.daterange,
			{
				startDate: !this.dt1 ? moment().day(-31) : this.dt1,
				endDate: !this.dt2 ? moment() : this.dt2,
			},
			function (d1, d2) {
				self.dateStart = d1.format("YYYY-MM-DD");
				self.dateEnd = d2.format("YYYY-MM-DD");
				self.data_type = self.data_type;
				self.filter.datatype = self.data_type;
				self.filter.dt1 = self.dateStart;
				self.filter.dt2 = self.dateEnd;
				self.onDate();
				var json = { dt1: self.filter.dt1, dt2: self.filter.dt2, ticket: self.filter.ticket };
				self.loadAllStatus(json);
			}
		);
		var isAdmin = this.userInfo.role_id < 6;
		var isShipper = this.userInfo.role_id > 10;
		var isKurir = this.userInfo.role_id == 9;
		this.table = createTable(e.tblticket, {
			title: "List Ticket",
			roles: this.$route.params.roles,
			ajax: "/ticket/history/arsip",
			frame: false,
			scrollX: true,
			autoWidth:false,
			deferRender : true,
			processing: true,
			language: {
				processing: '<i class="fa fa-spinner fa-spin" style="font-size:50px;color:black;"></i>'},
			selectedRowClass: "",
			toolbarEl: e.toolbar,
			lengthChange: true,
			filter: true,
			filterBy: [0, 1, 2],
			stateSave: true,
			paramData: function (d) {
				var filter = JSON.parse(JSON.stringify(self.filter));
				for (const key in filter) {
						d[key] = filter[key] === false ? 0: filter[key];
				}
			},
			// order: [[ 7, 'asc' ]],
			columns: [
				{
					data: "no_resi",
					render: function (data, type, row, meta) {
						return `<span class="link link-order text-info" style="cursor:pointer;" data-id="${row.order_no}">${row.no_resi}</span>`;
					},
				},
				{ data: "title" },
				{ data: "shipperName" },
				{
					data: "priorityName",
					render: function (data, type, row, meta) {
						var cls = 'secondary';
						if(data=='High')cls='danger';
						else if(data=='Urgent')cls='warning';
						return (
							"<span class='badge badge-"+cls+"'>" + data + "</span>"
						);
					},
				},
				{
					data: "status",
					render: function (data, type, row, meta) {
						if (data == 0) return "";
						else if (data == 1)
							return '<span class="badge badge-info">Open</span>';
						else if (data == 2)
							return '<span class="badge badge-success">Closed</span>';
						else if (data == 3)
							return '<span class="badge badge-warning">Pending</span>';
						else return "";
					},
				},
				{
					data: "pickupStatus",
					visible: isAdmin ? true : false,
					render: function (data, type, row, meta) {
						var badge = '';
						badge = self.infoStatus(row);
						return badge;
					},
				},
				{ data: "created_on" },
				{ data: "lastUpdate" },
				{ data: "aging", render: function (data, type, row, meta) { return data + ' Hari' } },
				{ data: "totalComment", class:'text-center', render: function (data, type, row, meta) {
					var replied = !row.replied?'':' <i class="fa fa-question"></i> ';
					return ('<div class="btn-comment" style="cursor:pointer;" data-id="'+row.ref_code+'"><i class="far fa-comment"></i> '+data+replied+'</div>');
				} },
			],
			rowCallback: function (row, data) {},
			drawCallback: function () {
					$(document).ready(function(){
						$('[data-toggle="tooltip"]').tooltip();	 
				});
			},
			initComplete: function () {
				$('.loading-overlay').removeClass('show');
			}
		});
	},
	components: {
		ArsipTicketComment
	},
};
</script>
<style>
.table-ticket .dataTables_scrollBody{min-height: 100px}
</style>